/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import { Box } from 'theme-ui';
import VisuallyHidden from '@reach/visually-hidden';

import strings from './strings.json';

const { cartItemAriaRemoveFromCart } = strings;

const RemoveItemIcon = (props) => {
  return (
    <Box {...props}>
      <VisuallyHidden>{cartItemAriaRemoveFromCart}</VisuallyHidden>

      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="far"
        data-icon="times-circle"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
        ></path>
      </svg>
    </Box>
  );
};

export default RemoveItemIcon;
